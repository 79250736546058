import React, { Fragment, useEffect, useState } from 'react';
import { IMAGES } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { getChatUserAction } from '../../../actions/chatAction';
import { getMessageHistoryAction } from '../../../actions/chatAction';
import Avatar from 'react-avatar';
import { FormattedMessage } from 'react-intl';
import { socket } from '../../../utils/socket';
import $ from 'jquery';
import { getParamsByName } from '../../../helper';
const Users = () => {
    var url_seller_id = getParamsByName(window.location.href, 'chat')
    url_seller_id = url_seller_id ? url_seller_id : 0;

    // console.log('socket---------------------->',socket);

    if (socket.connected === false) {
        socket.connected = true;
        // socket.disconnected = false;
    }
    const dispatch = useDispatch();

    const [active, setActive] = useState();

    var { chatuser } = useSelector(state => state.chatuser);
    // console.log('chatuserchatuserchatuserchatuserchatuser',chatuser);
    const user_id = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).id;

    useEffect(async () => {
        if (!chatuser || chatuser == null) {
            // dispatch(getChatUserAction());

            setTimeout(() => {
            dispatch(getChatUserAction());
            }, 2500);
        }
        setTimeout(() => {
            $('.ul_' + url_seller_id).trigger('click');
        }, 200);
    }, [dispatch, chatuser])

    const handleClickUser = (other_user_id, other_user_name, other_profile_picture_full_thumb) => {
        setActive(other_user_id);

        sessionStorage.setItem('other_user_id', other_user_id)
        sessionStorage.setItem('other_user_name', other_user_name)
        sessionStorage.setItem('other_profile_picture_full_thumb', other_profile_picture_full_thumb)
        dispatch(getMessageHistoryAction(user_id, other_user_id))
        socket.emit('total_message_count', {});
        $('.ul_' + other_user_id + ' li .total_unseen').hide();
    }

    return (
        <Fragment>
            <div className="col-md-3 lefts pl-5" style={{ boxShadow: "inset -11px 2px 8px -15px rgb(0 0 0 / 16%)" }}>
                <h2 className="mt-4 mb-3"><FormattedMessage id="Messages" defaultMessage="Messages" /></h2>
                {
                    chatuser && chatuser && chatuser.map((user) => {

                        // console.log('user--------------------->', user);
                        let l_id = user.user_id == user_id ? user.other_user_id : user.user_id;
                        let l_name = user.user_id == user_id ? user.company_name : user.other_company_name;
                        // let l_profile_picture_full_thumb = user.user_id == user_id?user.profile_picture:user.other_profile_pic;
                        let l_profile_picture_full_thumb = user.user_id == -1 ? IMAGES.CHAT_LOGO : user.user_id == user_id ? user.profile_picture : user.other_profile_pic;

                        return (
                            <ul className={"mesList ul_" + l_id} onClick={() => handleClickUser(l_id, l_name, l_profile_picture_full_thumb)}>
                                <li class={active === l_id ? 'active' : null}>
                                    {/* <img src={IMAGES.IMAGE_01} className="avtar_img" /> */}
                                    <Avatar name={user.name} className="avtar_img" color="#012F5B" size="45" value="86%" src={l_profile_picture_full_thumb && l_profile_picture_full_thumb} round />
                                    <h4>{l_name ? l_name : 'Cevaheer'}</h4>
                                    {/* <span>Awesome! Have a go..</span> */}
                                    {user.total_unseen > 0 ? <span className="float-right total_unseen">{user.total_unseen}</span> : ''}

                                </li>
                            </ul>
                        )
                    })
                }
            </div>
        </Fragment>
    )
}

export default Users
