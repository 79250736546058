import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from "react-alert";
import { clearError, getAttributes, updateDiamond, GetProductCount, clearMessage } from "../../actions/productActions";
import { validateFields } from '../../validation';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import $ from 'jquery';
import PopupSucess from '../layout/PopupSucess';
import Loader from '../layout/Loader';
import { getCategory } from '../../actions/categoryAction';
//import { Link } from 'react-router-dom';

const Dimonds = () => {

    const dispatch = useDispatch()
    const alert = useAlert()

    const { attributes } = useSelector(state => state.attributes)
    const { details } = useSelector(state => state.productDetails);

    useEffect(() => {
        dispatch(getAttributes('diamonds'))
    }, [dispatch])

    const [addproduct, setAddProduct] = useState({
        shape: "",
        id: "",
        category_slug: "",
        carat: "",
        color: "",
        clarity: "",
        cut: "",
        polish: "",
        symmetry: "",
        milky: "",
        availability_status: "",
        bgm: "",
        fluorescence: "",
        table_per: "",
        depth_per: "",
        mm_h: "",
        mm_l: "",
        mm_w: "",
        culet: "",
        certificate: "",
        certificate_number: "",
        discount: "",
        comment: "",
        price: "",
        stock_number: "",
        diamond_3d_link: "",
        fancy_color: "",
        fancy_intensity: "",
        fancy_overtone: "",
        is_fancy: "",

    })

    const [validerror, setValidError] = useState({
        stock_numberError: "",
        shapeError: "",
        caratError: "",
        colorError: "",
        clarityError: "",
        certificateError: "",
        milkyError: "",
        availabilityStatusError: "",
        priceError: "",
        depth_perError: "",
        mm_l_Error: "",
        mm_h_Error: "",
        mm_w_Error: "",
        table_perError: "",
    });

    const handleInputChange = (event) => {
        setValidError({
            stock_numberError: "",
            shapeError: "",
            caratError: "",
            colorError: "",
            clarityError: "",
            certificateError: "",
            milkyError: "",
            availabilityStatusError: "",
            priceError: "",
            depth_perError: "",
            mm_l_Error: "",
            mm_h_Error: "",
            mm_w_Error: "",
            table_perError: "",
        })
        // console.log("event.target.value", event.target.value, event.target.name);
        const newValue = event.target.value;
        const regex = /^-?[0-9+%.-]*$/;
        const isValid = regex.test(newValue) && (newValue.match(/-/g) || []).length <= 1 && (newValue.indexOf('-') <= 0);
        if (isValid) {
            event.target.value = newValue;
        } else {
            event.target.value = '';
        }

        if (event.target.name == "discount") {
            setAddProduct({ ...addproduct, discount: event.target.value, price: "" })
            if (event.target.value != "") {

                $('.price').attr('readonly', 'readonly');
            } else {

                $('.price').removeAttr('readonly');
            }
        } else if (event.target.name == "price") {
            setAddProduct({ ...addproduct, price: event.target.value, discount: "" })
            if (event.target.value != "") {
                $('.discount').attr('readonly', 'readonly');
            } else {
                $('.discount').removeAttr('readonly');
            }
        } else {
            setAddProduct({ ...addproduct, [event.target.name]: event.target.value })
        }

    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const stock_numberError = validateFields.validateStock_Number(addproduct.stock_number);
        const shapeError = validateFields.validateShape(addproduct.shape);
        const caratError = validateFields.validateCarat(addproduct.carat);
        const clarityError = validateFields.validateClarity(addproduct.clarity);
        const certificateError = validateFields.validateCertificate(addproduct.certificate);
        const milkyError = validateFields.validateMilky(addproduct.milky);
        const availabilityStatusError = validateFields.validateAvailabilityStatus(addproduct.availability_status);
        var priceError = false;
        var mm_l_Error = false;
        var mm_h_Error = false;
        var mm_w_Error = false;
        var table_perError = false;
        var depth_perError = false;

        if (addproduct.mm_l) {
            mm_l_Error = validateFields.validateMm_l(addproduct.mm_l);
        }
        if (addproduct.mm_h) {
            mm_h_Error = validateFields.validateMn_h(addproduct.mm_h);
        }
        if (addproduct.mm_w) {
            mm_w_Error = validateFields.validateMm_w(addproduct.mm_w);
        }
        if (addproduct.table_per) {
            table_perError = validateFields.validateTablePer(addproduct.table_per);
        }
        if (addproduct.depth_per) {
            depth_perError = validateFields.validateDepthPer(addproduct.depth_per);
        }
        if (!addproduct?.discount) {
            priceError = validateFields.validatePrice(addproduct.price);
        }



        if (addproduct.is_fancy === 0) {
            if ([stock_numberError, shapeError, caratError, clarityError, certificateError, priceError, availabilityStatusError, milkyError, depth_perError, table_perError, mm_w_Error, mm_l_Error, mm_h_Error].every(e => e === false)) {
                setValidError({
                    ...validerror, stock_numberError, shapeError, caratError, clarityError, certificateError, priceError, availabilityStatusError, milkyError, depth_perError, table_perError, mm_w_Error, mm_l_Error, mm_h_Error
                })
                await dispatch(updateDiamond(addproduct))
                dispatch(GetProductCount());
                dispatch(getCategory());
            } else {
                setValidError({
                    ...validerror, stock_numberError, shapeError, caratError, clarityError, certificateError, priceError, availabilityStatusError, milkyError, depth_perError, table_perError, mm_w_Error, mm_l_Error, mm_h_Error
                })
            }
        } else {
            if ([stock_numberError, shapeError, caratError, clarityError, certificateError, priceError, availabilityStatusError, depth_perError, table_perError, mm_w_Error, mm_l_Error, mm_h_Error].every(e => e === false)) {
                setValidError({
                    ...validerror, stock_numberError, shapeError, caratError, clarityError, certificateError, priceError, availabilityStatusError, depth_perError, table_perError, mm_w_Error, mm_l_Error, mm_h_Error
                })

                await dispatch(updateDiamond(addproduct))
                dispatch(GetProductCount());
                dispatch(getCategory());
            }
        }
        return;
    }

    const { updateproduct, data, error, loading } = useSelector(state => state.updateDiamondReducer);

    useEffect(() => {

        if (updateproduct) {
            $("#popup-click-sucess").trigger('click');
            alert.success(updateproduct);
            //console.log("updateproduct---",updateproduct);
            dispatch(clearMessage())
        }

        if (error) {
            alert.error(error)
            dispatch(clearError())
        }

    }, [updateproduct, dispatch, error, alert, data]);


    useEffect(() => {
        // console.log("details--", details);
        // console.log("addproduct--", addproduct);
        if (details) {
            setAddProduct({
                category_slug: "diamonds",
                id: details && details?.productDetails.id,
                stock_number: details && details?.productDetails.stock_number,
                shape: details && details?.productDetails.shape,
                carat: details && details?.productDetails.carat,
                color: details && details?.productDetails.color,
                clarity: details && details?.productDetails.clarity,
                cut: details && details?.productDetails.cut,
                polish: details && details?.productDetails.polish,
                availability_status: details && details?.productDetails.availability_status && details?.productDetails.availability_status.attribute_id,
                milky: details && details?.productDetails.milky && details?.productDetails.milky.attribute_id,
                symmetry: details && details?.productDetails.symmetry,
                fluorescence: details && details?.productDetails.a_fluorescence && details?.productDetails.a_fluorescence.attribute_id,
                bgm: details && details?.productDetails.bgm && (details?.productDetails.bgm == 'Yes' ? '0' : '1'),
                depth: details && details?.productDetails.depth,
                measurement: details && details?.productDetails.measurement,
                culet: details && details?.productDetails.culet,
                certificate: details && details?.productDetails.certificate,
                certificate_number: details && details?.productDetails.certificate_number,
                discount: details && details?.productDetails.discount,
                comment: details && details?.productDetails.comment,
                price: details && details?.productDetails.price,
                mm_w: details?.productDetails.mm_w,
                mm_h: details?.productDetails.mm_h,
                mm_l: details?.productDetails.mm_l,
                table_per: details?.productDetails.table_per,
                depth_per: details?.productDetails.depth_per,
                diamond_3d_link: details?.productDetails.diamond_3d_link,
                fancy_color: details?.productDetails.fancy_color,
                fancy_intensity: details?.productDetails.fancy_intensity,
                fancy_overtone: details?.productDetails.fancy_overtone,
                is_fancy: details?.productDetails.is_fancy
            })
        }
    }, [details])

    // const handleSwitchColor = (e) => {
    //     if (e.target.checked) {

    //         console.log("*****************************************");
    //         $('#fancy_color').css({
    //             display: 'block'
    //         })
    //         $('#fancy_color_intensity').css({
    //             display: 'block'
    //         })
    //         $('#fancy_color_overtone').css({
    //             display: 'block'
    //         })
    //         $('#color').css({
    //             display: 'none'
    //         })
    //         $('#discount').css({
    //             display: 'none'
    //         })
    //         $('#white_diamond_milky').css({
    //             display: 'none'
    //         })
    //         $('.white_diamond_bgm').css({
    //             display: 'none'
    //         })
    //         $('#diamond_color').val("1")
    //     } else {
    //        console.log("---------------------------");

    //         $('#fancy_color').css({
    //             display: 'none'
    //         })
    //         $('#fancy_color_intensity').css({
    //             display: 'none'
    //         })
    //         $('#fancy_color_overtone').css({
    //             display: 'none'
    //         })
    //         $('#color').css({
    //             display: 'block'
    //         })
    //         $('#discount').css({
    //             display: 'block'
    //         })
    //         $('#white_diamond_milky').css({
    //             display: 'block'
    //         })
    //         $('.white_diamond_bgm').css({
    //             display: 'block'
    //         })
    //         $('#diamond_color').val("0")
    //     }

    // }
    // $('#diamond_color').val(addproduct.is_fancy);
    // let diamond_color = $('#diamond_color').val();

    if (addproduct.is_fancy == 1) {
        //$("#diamond_color").prop("checked", true);
        $('#fancy_color').css({
            display: 'block'
        })
        $('#fancy_color_intensity').css({
            display: 'block'
        })
        $('#fancy_color_overtone').css({
            display: 'block'
        })
        $('#color').css({
            display: 'none'
        })
        $('#discount').css({
            display: 'none'
        })
        $('#white_diamond_milky').css({
            display: 'none'
        })
        $('.white_diamond_bgm').css({
            display: 'none'
        })
    } else {
        //$("#diamond_color").prop("checked", false)
        $('#fancy_color').css({
            display: 'none'
        })
        $('#fancy_color_intensity').css({
            display: 'none'
        })
        $('#fancy_color_overtone').css({
            display: 'none'
        })
        $('#color').css({
            display: 'block'
        })
        $('#discount').css({
            display: 'block'
        })
        $('#white_diamond_milky').css({
            display: 'block'
        })
        $('.white_diamond_bgm').css({
            display: 'block'
        })
    }

    if (addproduct?.bgm == "1") {
        $("#bgmCheckbox").prop("checked", true);
    } else {
        $("#bgmCheckbox").prop("checked", false);
    }

    const bgmCheckbox = document.getElementById('bgmCheckbox');
    if (bgmCheckbox) {
        bgmCheckbox.addEventListener('change', function () {
            if (this.checked) {
                addproduct.bgm = "1";
            } else {
                addproduct.bgm = "0";
            }
        });
    }
    console.log(addproduct);
    return (
        <Fragment>
            {loading && <Loader />}
            <Fragment>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.stockno"
                                        defaultMessage="Stock No."
                                    />
                                    <span className="strike_red">*</span></label>
                                <input type="hidden" name="id" value={addproduct.id} />
                                <input type="hidden" name="category_slug" value="diamonds" />

                                <input className={classnames("form-control", { 'is-valid': validerror.stock_numberError === false }, { 'is-invalid': validerror.stock_numberError })} type="text" placeholder=" Enter Stock No." onChange={handleInputChange} name="stock_number" value={addproduct.stock_number} />
                                <p className="invalid-feedback">{validerror.stock_numberError}</p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.carat"
                                        defaultMessage="Carat"
                                    />
                                    <span className="strike_red">*</span></label>
                                <input type="text" className={classnames("form-control", { 'is-valid': validerror.caratError === false }, { 'is-invalid': validerror.caratError })} placeholder="Enter Carat" name="carat" onChange={handleInputChange} value={addproduct.carat} />
                                <p className="invalid-feedback">{validerror.caratError}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.shape"
                                        defaultMessage="Shape"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select
                                    className={classnames("form-control", { 'is-valid': validerror.shapeError === false }, { 'is-invalid': validerror.shapeError })}
                                    onChange={handleInputChange}
                                    name="shape"
                                    value={addproduct?.shape}>
                                    <FormattedMessage id="addsingleproduct.chooseshape" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributes && attributes.shape && attributes?.shape.map((shape) => {
                                            return (
                                                <option value={shape?.id}>{shape?.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.shapeError}</p>
                            </div>
                        </div>
                        {/* <div className="filter_top switch_color">
                            <div className="cus_switch d-flex align-item-center">
                                <p className="font-14 fm black-3B pr-3"><FormattedMessage
                                    id="addsingleproduct.White"
                                    defaultMessage="White"
                                /></p>
                                <label className="switch">
                                    <input type="checkbox" onChange={handleSwitchColor} id ="diamond_color"/>
                                    <span className="slider round"></span>
                                </label>
                                <p className="gray-75 font-14 fm pl-3"> <FormattedMessage
                                    id="addsingleproduct.Fancy"
                                    defaultMessage="Fancy"
                                /></p>
                            </div>
                        </div> */}
                        <div id="color" className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.color"
                                        defaultMessage="Color"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select className={classnames("form-control", { 'is-valid': validerror.colorError === false }, { 'is-invalid': validerror.colorError })} onChange={handleInputChange} name="color" value={addproduct?.color}>
                                    <FormattedMessage id="addsingleproduct.choosecolor" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributes && attributes.color && attributes.color.map((color) => {
                                            return (
                                                <option value={color.id}>{color.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.colorError}</p>
                            </div>
                        </div>



                        <div id="fancy_color" style={{ display: 'none' }} className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.fancycolor"
                                        defaultMessage="Fancy Color"
                                    />
                                </label>
                                <select className="form-control" onChange={handleInputChange} name="fancy_color" value={addproduct?.fancy_color}>
                                    <FormattedMessage id="addsingleproduct.choosefancycolor" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributes && attributes.fancy_color && attributes.fancy_color.map((fancy_color) => {
                                            return (
                                                <option value={fancy_color.id}>{fancy_color.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">

                        </div>
                    </div>

                    <div className="row">

                    </div>


                    <div className="row">
                        <div className="col-lg-4" id="fancy_color_intensity" style={{ display: 'none' }}>
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.fancycolorintensity"
                                        defaultMessage="Fancy Intensity"
                                    />
                                </label>
                                <select className="form-control" onChange={handleInputChange} name="fancy_intensity" value={addproduct.fancy_intensity}>
                                    <FormattedMessage id="addsingleproduct.choosefancycolorintensity" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributes && attributes.fancy_intensity && attributes.fancy_intensity.map((fancy_intensity) => {
                                            return (
                                                <option value={fancy_intensity.id}>{fancy_intensity.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>


                        <div id="fancy_color_overtone" style={{ display: 'none' }} className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.fancycolorovertone"
                                        defaultMessage="Fancy Overtone"
                                    />
                                </label>
                                <select className="form-control" onChange={handleInputChange} name="fancy_overtone" value={addproduct.fancy_overtone}>
                                    <FormattedMessage id="addsingleproduct.choosefancycolorovertone" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributes && attributes.fancy_overtone && attributes.fancy_overtone.map((fancy_overtone) => {
                                            return (
                                                <option value={fancy_overtone.id}>{fancy_overtone.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.clarity"
                                        defaultMessage="Clarity"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select className={classnames("form-control", { 'is-valid': validerror.clarityError === false }, { 'is-invalid': validerror.clarityError })} onChange={handleInputChange} name="clarity" value={addproduct?.clarity}>
                                    <FormattedMessage id="addsingleproduct.chooseclarity" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributes && attributes.clarity && attributes.clarity.map((clarity) => {
                                            return (
                                                <option value={clarity.id}>{clarity.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.clarityError}</p>
                            </div>
                        </div>


                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.cut"
                                        defaultMessage="Cut"
                                    />
                                </label>
                                <select className="form-control" onChange={handleInputChange} name="cut" value={addproduct?.cut}>
                                    <FormattedMessage id="addsingleproduct.choosecut" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributes && attributes.cut && attributes.cut.map((cut) => {
                                            return (
                                                <option value={cut.id}>{cut.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.polish"
                                        defaultMessage="Polish"
                                    />
                                </label>
                                <select className="form-control" onChange={handleInputChange} name="polish" value={addproduct.polish}>
                                    <FormattedMessage id="addsingleproduct.choosepolish" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributes && attributes.polish && attributes.polish.map((polish) => {
                                            return (
                                                <option value={polish.id}>{polish.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.symmetry"
                                        defaultMessage="Symmetry"
                                    />
                                </label>
                                <select className="form-control" onChange={handleInputChange} name="symmetry" value={addproduct.symmetry}>
                                    <FormattedMessage id="addsingleproduct.choosesymmetry" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributes && attributes.symmetry && attributes.symmetry.map((symmetry) => {
                                            return (
                                                <option value={symmetry.id}>{symmetry.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.fluorescence"
                                        defaultMessage="Fluorescence"
                                    />
                                </label>
                                <select className="form-control" onChange={handleInputChange} name="fluorescence" value={addproduct.fluorescence}>
                                    <FormattedMessage id="addsingleproduct.choosefluorescence" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributes && attributes.fluorescence && attributes.fluorescence.map((flurescence) => {
                                            return (
                                                <option value={flurescence.id} selected="{addproduct.fluorescence}">{flurescence.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-lg-4" id="white_diamond_milky">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.milky"
                                        defaultMessage="Milky"
                                    />
                                    <span className="strike_red">*</span>
                                </label>

                                <select className={classnames("form-control milky", { 'is-valid': validerror.clarityError === false }, { 'is-invalid': validerror.milkyError })} onChange={handleInputChange} name="milky" value={addproduct.milky}>
                                    <FormattedMessage id="addsingleproduct.choosemilky" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributes && attributes.milky && attributes.milky.map((milky) => {
                                            return (
                                                <option value={milky.id}>{milky.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.milkyError}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row white_diamond_bgm" >
                        <div className="col-lg-1 white_diamond_bgm">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.bgm"
                                        defaultMessage="NO BGM"
                                    />
                                    <span className="strike_red">*</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-4 white_diamond_bgm">
                            <div className="cus_switch d-flex align-item-center">
                                {/* <p className="font-14 fm black-3B pr-3"><FormattedMessage
                                    id="addsingleproduct.No"
                                    defaultMessage="No"
                                /></p> */}
                                <label className="switch">
                                    <input type="checkbox" name="bgm" value={addproduct?.bgm} class='bgm' id="bgmCheckbox" />

                                    <span className="slider round"></span>
                                </label>
                                {/* <p className="gray-75 font-14 fm pl-3"> <FormattedMessage
                                    id="addsingleproduct.Yes"
                                    defaultMessage="Yes"
                                /></p> */}
                            </div>
                        </div>
                    </div>

                    <div className="row" >

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.status"
                                        defaultMessage="Status"
                                    /><span className="strike_red">*</span>
                                </label>
                                <select className={classnames("form-control", { 'is-valid': validerror.clarityError === false }, { 'is-invalid': validerror.availabilityStatusError })} onChange={handleInputChange} name="availability_status" value={addproduct?.availability_status}>
                                    <FormattedMessage id="addsingleproduct.choose_status" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributes && attributes.availability_status && attributes.availability_status.filter((availability_status) => availability_status.id !== 2321).map((availability_status) => {
                                            return (
                                                <option value={availability_status.id}>{availability_status.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.availabilityStatusError}</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.measurement"
                                        defaultMessage="Measurement"
                                    />
                                </label>
                                <div className="row">
                                    <div className="col-md-4 mb-md-0 mb-3">
                                        <input type="text" className={classnames("form-control", { 'is-valid': validerror.mm_l_Error === false }, { 'is-invalid': validerror.mm_l_Error })} placeholder="length" name="mm_l" onChange={handleInputChange} value={addproduct?.mm_l} />
                                        <p className="invalid-feedback">{validerror.mm_l_Error}</p>
                                    </div>
                                    <div className="col-md-4 mb-md-0 mb-3">
                                        <input type="text" className={classnames("form-control", { 'is-valid': validerror.mm_w_Error === false }, { 'is-invalid': validerror.mm_w_Error })} placeholder="width" name="mm_w" onChange={handleInputChange} value={addproduct?.mm_w} />
                                        <p className="invalid-feedback">{validerror.mm_w_Error}</p>

                                    </div>
                                    <div className="col-md-4 mb-md-0 mb-0">
                                        <input type="text" className={classnames("form-control", { 'is-valid': validerror.mm_h_Error === false }, { 'is-invalid': validerror.mm_h_Error })} placeholder="depth" name="mm_h" onChange={handleInputChange} value={addproduct?.mm_h} />
                                        <p className="invalid-feedback">{validerror.mm_h_Error}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.table"
                                        defaultMessage="Table"
                                    /> %
                                </label>
                                <input type="text" className={classnames("form-control", { 'is-valid': validerror.table_perError === false }, { 'is-invalid': validerror.table_perError })} placeholder="Enter Table %" name="table_per" onChange={handleInputChange} value={addproduct?.table_per} />
                                <p className="invalid-feedback">{validerror.table_perError}</p>

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.depth"
                                        defaultMessage="Depth"
                                    />
                                    %</label>
                                <input type="text" className={classnames("form-control", { 'is-valid': validerror.depth_perError === false }, { 'is-invalid': validerror.depth_perError })} placeholder="Enter Depth %" name="depth_per" onChange={handleInputChange} value={addproduct.depth_per} />
                                <p className="invalid-feedback">{validerror.depth_perError}</p>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.certificate"
                                        defaultMessage="Certificate"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select className={classnames("form-control", { 'is-valid': validerror.certificateError === false }, { 'is-invalid': validerror.certificateError })} onChange={handleInputChange} name="certificate" value={addproduct.certificate}>
                                    <FormattedMessage id="addsingleproduct.choosecertificate" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributes && attributes.certificate && attributes.certificate.map((certificate) => {
                                            return (
                                                <option value={certificate.id}>{certificate.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.certificateError}</p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.certificate-no"
                                        defaultMessage="Cetificate No."
                                    />
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Certificate No."
                                    onChange={handleInputChange}
                                    name="certificate_number"
                                    value={addproduct.certificate_number} />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4" id="discount">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.discount"
                                        defaultMessage="Discount"
                                    />
                                    %  <span className="strike_red">*</span></label>
                                <input
                                    className="form-control discount"
                                    type="text"
                                    placeholder="Enter Discount"
                                    onChange={handleInputChange}
                                    name="discount"
                                    value={addproduct?.discount ? addproduct?.discount : ""} />
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.price-ct"
                                        defaultMessage="Price/ct"
                                    />
                                    <span className="strike_red">*</span></label>
                                <input
                                    className={classnames("form-control price", { 'is-valid': validerror?.priceError === false }, { 'is-invalid': validerror?.priceError })}
                                    type="text"
                                    placeholder="Enter Price"
                                    onChange={handleInputChange}
                                    name="price"
                                    value={addproduct?.price ? addproduct?.price : ""} />
                                <p className="invalid-feedback">{validerror?.priceError}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-8">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.comment"
                                        defaultMessage="Supplier Comment (optional)"
                                    />
                                </label>
                                <textarea placeholder="Comment" rows="5" className="form-control" onChange={handleInputChange} name="comment" value={addproduct.comment}></textarea>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-lg-8">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.add3dlink"
                                        defaultMessage="3d Link"
                                    />
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="https://www.v360.in"
                                    value={addproduct?.diamond_3d_link}
                                    onChange={(e) => setAddProduct({ ...addproduct, diamond_3d_link: e?.target?.value })}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="row actions my-5">
                        <div className="col-md-6">
                            <button type="submit" className="btn btn-blue mb-2">
                                <FormattedMessage
                                    id="Save"
                                    defaultMessage="Save"
                                />
                            </button>

                        </div>


                    </div>
                </form>
            </Fragment>
            {
                data &&
                <PopupSucess title={'SUCCESS'} props={data} />
            }
        </Fragment>
    )
}

export default Dimonds
