import LOGO from '../assets/images/logo.svg';
import VIEW_SVG from '../assets/images/svg/view.svg';
import LOGO_BLACK from '../assets/images/logo-black.png';
import CHECK_SVG from '../assets/images/svg/check.svg';
import BROWSER_IMAGE from '../assets/images/Browser.png';
import FACEBOOK_IMAGE from '../assets/images/svg/facebook.svg';
import INSTAGRAM_IMAGE from '../assets/images/svg/insta.svg';
import TWITTER_IMAGE from '../assets/images/svg/twitter.svg';
import LINKEDIN_IMAGE from '../assets/images/svg/linkedin.svg';
import NO_IMAGE from '../assets/images/no-Image.png';
import LONG_ARROW from '../assets/images/svg/long-arrow.svg';
import AVATAR from '../assets/images/avatar.png';
import PLAN_ICON from '../assets/images/plan-icon.png';
import SEARCH_SVG from '../assets/images/svg/Icon-search.svg';
import EMAIL_SVG from '../assets/images/svg/ic16-email.svg';
import HEART_SVG from '../assets/images/svg/ic20-heart-full.svg';
import PROFILE_IMG from '../assets/images/img-01.png';
import ARROW_SVG from '../assets/images/svg/arrow.svg';
import ARROW_RIGHT from '../assets/images/svg/arrow-right.svg';
import CONTACT_SVG from '../assets/images/svg/contact-us.svg';
import WHITE_LOGO from '../assets/images/logo-white.png';
import CHAT_LOGO from '../assets/images/chat_logo.png';
import WINDOW_SVG from '../assets/images/svg/window.svg';
import MOBILE_SVG from '../assets/images/svg/mobile.svg';
import OVAL_SVG from '../assets/images/svg/Oval.svg';
import ICON from '../assets/images/Icon.png';
import THREE_DOT from '../assets/images/svg/three-dots.svg'
import BACK_BUTTON_SVG from '../assets/images/svg/back_btn.svg';
import VISA_IMG from '../assets/images/VISA.png';
import L1_SVG from '../assets/images/svg/L1.svg';
import W1_IMG from '../assets/images/w1.png';
import BOX_IMG from '../assets/images/box.png';
import MESSAGE_IMG from '../assets/images/message.png';
import HEART_IMG from '../assets/images/heart1.png';
import HEARTSHOW_IMG from '../assets/images/heart.png';
import FAV_IMG from '../assets/images/fav1.png';
import DOWNLOAD_SVG from '../assets/images/svg/downloads.svg';
import MOVE_SVG from '../assets/images/svg/move.svg';
import CLOSE_WHITE from '../assets/images/svg/close-white.svg';
import IMAGE_01 from '../assets/images/cevaheer-icon-chat.png';
import C1_PNG from '../assets/images/c1.png';
import N1_PNG from '../assets/images/n1.png';
import COLOR_STONE_THUMB from '../assets/images/color-stone-thumb.png';
import ADD_PNG from '../assets/images/add.png';
import REMOVE_PNG from '../assets/images/remove.png';
import DEGREE_PNG from '../assets/images/360.png';
import COLOR_STONE_PRO_PNG from '../assets/images/color-stone-pro.png';
import COLOR_STONE_LARGE_PNG from '../assets/images/color-stone-large.png';
import EDIT_PNG from '../assets/images/edit.png';
import COMPARE_IMG from '../assets/images/svg/compare.svg';
import NO_IMAGE_JPG from '../assets/images/no_image.jpg';
import DOWNLOAD from '../assets/images/download.png';
import COMMENTSVG from '../assets/images/svg/Figma Icons/comment.svg';
import EmptyPageImage from '../assets/images/404-img.png';
import VideoDefaultThumb from '../assets/images/video-thumb.jpg';


//dimonds
import PRO_THUMB from '../assets/images/pro-thumb.png';
import PRO_PNG from '../assets/images/pro.png';
import PRO_LARGE_PNG from '../assets/images/pro-large.png';

//excelTemplate
import WATCH_TEMPLATE from '../assets/excelTemplate/Watches_Upload_Template.xls';
import DIAMOND_TEMPLATE from '../assets/excelTemplate/Diamond_Upload_Template.xls';
import PARCEL_TEMPLATE from '../assets/excelTemplate/Parcel_Upload_Template.xls';
import COLOR_STONE_TEMPLATE from '../assets/excelTemplate/ColorStones_Upload_Template.xls';
import WEDDING_BAND_TEMPLATE from '../assets/excelTemplate/WeddingBands_Upload_Template.xls';
import JEWELRY_TEMPLATE from '../assets/excelTemplate/Jewelry_Upload_Template.xls';




export const IMAGES = {
    WATCH_TEMPLATE:WATCH_TEMPLATE,
    DIAMOND_TEMPLATE:DIAMOND_TEMPLATE,
    PARCEL_TEMPLATE:PARCEL_TEMPLATE,
    COLOR_STONE_TEMPLATE:COLOR_STONE_TEMPLATE,
    WEDDING_BAND_TEMPLATE:WEDDING_BAND_TEMPLATE,
    JEWELRY_TEMPLATE:JEWELRY_TEMPLATE,
    
    LOGO:LOGO,
    VIEW_SVG:VIEW_SVG,
    LOGO_BLACK:LOGO_BLACK,
    WHITE_LOGO:WHITE_LOGO,
    CHECK_SVG:CHECK_SVG,
    BROWSER_IMAGE:BROWSER_IMAGE,
    FACEBOOK_IMAGE:FACEBOOK_IMAGE,
    INSTAGRAM_IMAGE:INSTAGRAM_IMAGE,
    TWITTER_IMAGE:TWITTER_IMAGE,
    LINKEDIN_IMAGE:LINKEDIN_IMAGE,
    NO_IMAGE:NO_IMAGE,
    LONG_ARROW:LONG_ARROW,
    AVATAR:AVATAR,
    PLAN_ICON:PLAN_ICON,
    SEARCH_SVG:SEARCH_SVG,
    EMAIL_SVG:EMAIL_SVG,
    HEART_SVG:HEART_SVG,
    PROFILE_IMG:PROFILE_IMG,
    ARROW_SVG:ARROW_SVG,
    ARROW_RIGHT:ARROW_RIGHT,
    CONTACT_SVG:CONTACT_SVG,
    WINDOW_SVG:WINDOW_SVG,
    MOBILE_SVG:MOBILE_SVG,
    OVAL_SVG:OVAL_SVG,
    ICON:ICON,
    BACK_BUTTON_SVG:BACK_BUTTON_SVG,
    VISA_IMG:VISA_IMG,
    L1_SVG:L1_SVG,
    W1_IMG:W1_IMG,
    BOX_IMG:BOX_IMG,
    MESSAGE_IMG:MESSAGE_IMG,
    HEART_IMG:HEART_IMG,
    FAV_IMG:FAV_IMG,
    DOWNLOAD_SVG:DOWNLOAD_SVG,
    MOVE_SVG:MOVE_SVG,
    CLOSE_WHITE:CLOSE_WHITE,
    IMAGE_01:IMAGE_01,
    C1_PNG:C1_PNG,
    COLOR_STONE_THUMB:COLOR_STONE_THUMB,
    ADD_PNG:ADD_PNG,
    REMOVE_PNG:REMOVE_PNG,
    DEGREE_PNG:DEGREE_PNG,
    COLOR_STONE_PRO_PNG:COLOR_STONE_PRO_PNG,
    COLOR_STONE_LARGE_PNG:COLOR_STONE_LARGE_PNG,
    PRO_THUMB:PRO_THUMB,
    PRO_PNG:PRO_PNG,
    PRO_LARGE_PNG:PRO_LARGE_PNG,
    THREE_DOT:THREE_DOT,
    EDIT_PNG:EDIT_PNG,
    N1_PNG:N1_PNG,
    HEARTSHOW_IMG:HEARTSHOW_IMG,
    COMPARE_IMG:COMPARE_IMG.anchor,
    NO_IMAGE_JPG:NO_IMAGE_JPG,
    DOWNLOAD:DOWNLOAD,
    EmptyPageImage:EmptyPageImage,
    COMMENTSVG:COMMENTSVG,
    VideoDefaultThumb:VideoDefaultThumb,
    CHAT_LOGO:CHAT_LOGO,
}
export const JEWELER_ROLE = 1
export const SUPPLIER_ROLE = 2
// export const JEWELER_SMALL = 10
// export const JEWELER_SMALL_SEARCHES = 20
export const JEWELER_SMALL = 22
export const JEWELER_SMALL_SEARCHES = 30

export const FINISH_COMB = [[1],[0,1],[0,1,2],[0,1,2,3]]
export const SEARCH_LIMIT_MSG = 'Your monthly search limit is finished. Upgrade and enjoy unlimited search.';
