import React, { Fragment } from 'react'
import { Line, Circle } from 'rc-progress';
import { FormattedMessage } from 'react-intl';


const ProgressLoader = (props) => {

  return (
      <Fragment>
        <p className='lode-perc'><span><strong><FormattedMessage id="Uploading" defaultMessage="Uploading"/></strong> {props ? props && props.tempData && props.tempData.percentage : 0}%</span>  <Circle className='circle' percent={props ? props && props.tempData && props.tempData.percentage : 0} strokeWidth="4" strokeColor="#D3D3D3" /></p> 
      </Fragment>
  )
}

export default ProgressLoader