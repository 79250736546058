import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { url, postPerPage, hrdurl, giaurl } from '../../../../config';
import { FormattedMessage, useIntl } from 'react-intl';
import { IMAGES } from '../../../../constants';
import { myProductAction, myProductFilterByAscending, myProductFilter, myProductSelectAction, myProductActiveAll, myProductDeactiveAll, myProductShowSold, clearMessage, myProductDeleteAll, GetProductCount, myProductStatusAction } from '../../../../actions/productActions';
import { checkAdvertisementPlan } from '../../../../actions/Advertisement';
import { getLastURL, handleSortBy, pColmDisplay, GenerateCertificatePdf, handleSortByAction, capitalizeFirstLetterWithOnlyLetter, numberWithCommas, totalPriceDisplay, totalPriceDisplayforDiscount, totalCarat } from '../../../../helper';
import Loader from '../../../layout/Loader';
import { diamondsJson, selectActionJson } from '../../../../utils/json';
import $ from 'jquery';
import { getCategory } from '../../../../actions/categoryAction';
import { useAlert } from 'react-alert';
import ReactPaginate from "react-paginate";
import Popup from '../popup';
import PopupConfirm from '../../../layout/PopupConfirm';
import MyProductsSideFilter from '../../../products/whiteDimonds/MyProductsSideFilter';

const WhiteDimonds = (props) => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const alert = useAlert();
    const category_slug = getLastURL(location);

    useEffect(() => {
        dispatch(myProductAction(category_slug, 1));
    }, [dispatch])

    const { myproduct, loading } = useSelector(state => state.myproduct);
    const { myproductselectaction } = useSelector(state => state.myproductselectaction);
    const { myproductstatusaction } = useSelector(state => state.myproductstatusaction);
    const { myproductactiveall } = useSelector(state => state.myproductactiveall);

    const [filterdisplayname, setFilterDisplayName] = useState(<FormattedMessage id="Select Sort Option" />);
    const [actionname, setActionName] = useState(<FormattedMessage id="Actions" />)
    const [search, setSearch] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(postPerPage);

    const [selectAllStock, setSelectAllStock] = useState(new Array(myproduct && myproduct.products && myproduct.products.data && myproduct.products.data.length).fill(false));
    const [selectedAction, setSeletedAction] = useState();
    const [selectedId, setSelectedId] = useState([]);
    const selectedArray = [];
    const [checkHead, setCheckHead] = useState(false);
    const [sold, setSold] = useState(false);
    const { checkPlan } = useSelector(state => state.checkPlanReducer);
    const [prod_id, setCurrentId] = useState(0);
    const [showFilters, setShowFilters] = useState(false);

    const handlePageChange = ({ selected }) => {
        props.updateResetPageHandler(selected)
        dispatch(myProductAction(category_slug, (selected + 1)));
    }

    const pageCount = Math.ceil((myproduct && myproduct.products && myproduct.products.total) / postsPerPage);

    const handleAcendingFilter = (value, type, displayname) => {
        setFilterDisplayName(displayname)
        $('.sort_by_list').css({
            display: "none"
        })
        props.tabChangeHandler(1)
        dispatch(myProductFilterByAscending(category_slug, value, type))
    }

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    const handleSearchFilter = (e) => {
        if (e.key === 'Enter') {
            dispatch(myProductFilter(category_slug, { search: search }));
        }
    }
    const handleSearchIconClick = () => {
        dispatch(myProductFilter(category_slug, { search: search }));
    };

    const handleStockManagement = (id, position) => {
        const updatedSelectAllStock = selectAllStock && selectAllStock.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setSelectAllStock(updatedSelectAllStock);

        if (!selectedId.includes(id)) {
            setSelectedId([...selectedId, id]);
        } else {
            selectedId.splice(selectedId.indexOf(id), 1);
        }
    }

    const handlecheckPlan = (id) => {
        dispatch(checkAdvertisementPlan(id));
        setCurrentId(id);
    }

    const selectAll = (selectAllStock) => {

        if (selectAllStock.every(e => e === false)) {
            setSelectAllStock(new Array(myproduct && myproduct.products && myproduct.products.data && myproduct.products.data.length).fill(true));

            myproduct && myproduct.products && myproduct.products.data && myproduct.products.data.map((data, key) => {
                selectedArray.push(data.id);
            })
            setCheckHead(true);
            setSelectedId(selectedArray);

        } else {
            setSelectAllStock(new Array(myproduct && myproduct.products && myproduct.products.data && myproduct.products.data.length).fill(false));
            selectedArray.pop();
            setSelectedId([]);
            setCheckHead(false);
        }

    }

    const handleSelectAction = (event, value) => {

        $('.sort_by_action').css({
            display: "none"
        })

        if (selectedId.every(e => e === false) || selectedId === null) {
            alert.error('Please select stock');
            setSeletedAction("");
            return;
        }
        setSeletedAction(value);
        dispatch(myProductSelectAction(selectedId, value));
        dispatch(GetProductCount());
        setSold(false);

    }

    const handleActiveAll = async () => {
        await dispatch(myProductActiveAll());
        dispatch(myProductAction(category_slug));
        dispatch(GetProductCount());
        dispatch(getCategory());

    }

    const handleInActiveAll = async () => {
        await dispatch(myProductDeactiveAll(category_slug));
        dispatch(myProductAction(category_slug));
        dispatch(GetProductCount());
        dispatch(getCategory());

    }

    const handleResForm = async (status) => {
        if (status) {
            await dispatch(myProductDeleteAll(category_slug));
            await dispatch(myProductAction(category_slug));
            await dispatch(GetProductCount());
            await dispatch(getCategory());
        }
        $("#dismiss-modal").trigger('click');

    }

    const handleDeleteAllClick = (event) => {
        $("#popup-click-confirm").trigger('click');
    }

    useEffect(() => {
        if (checkPlan) {
            if (checkPlan?.data) {
                console.log("in checkplan");
                history.push(url + `/advertise-plan-details/${prod_id}`);
            } else {
                alert.error(checkPlan['message']);
            }
            dispatch(checkAdvertisementPlan("RESET"));
        }
        //setCurrentId([]);
    }, [checkPlan])

    useEffect(() => {
        if (myproductselectaction) {
            alert.success(myproductselectaction);
            dispatch(clearMessage());
            setSelectAllStock([]);
            setSelectedId([]);

            setCheckHead(false);
            setSeletedAction("");
            dispatch(myProductAction(category_slug));
            dispatch(getCategory());
            return
        }
        if (myproductactiveall) {
            alert.success(<div className="react-alert-change-css">{myproductactiveall}</div>);
            dispatch(clearMessage());
            return
        }

        if (myproductstatusaction) {
            alert.success(<div className="react-alert-change-css">{myproductstatusaction}</div>);
            dispatch(clearMessage());
            return
        }
    }, [myproductselectaction, myproductactiveall, myproductstatusaction])

    const handleSoldProducts = (event) => {
        setSold(event.target.checked);
        dispatch(myProductShowSold(category_slug, event.target.checked));
    }

    const handleSingleActivate = async (action, id) => {
        let ids = [];
        ids.push(id)
        await dispatch(myProductSelectAction(ids, action));
        await dispatch(myProductAction(category_slug));
        dispatch(GetProductCount());
        dispatch(getCategory());
    }

    const handleStatusChange = async (action, id) => {
        await dispatch(myProductStatusAction(id, action));
        await dispatch(myProductAction(category_slug));
        dispatch(GetProductCount());
        dispatch(getCategory());
    }

    const handleDetails = (id) => {
        history.push(url + `/details/diamonds/${id}`);
    }

    return (
        <Fragment>
            {loading && <Loader />}
            <Fragment>
                <div className="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                    <div className="titles mt-4 mb-3 pb-3">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <h2 className="m-0"><FormattedMessage id="category.diamonds" />
                                    <span className="pro_nub w-auto px-2"> {myproduct && myproduct?.products && myproduct?.products?.total} </span>
                                </h2>
                            </div>

                            <div className="col-xl-9 col-lg-8 col-sm-6 col-md-6 hides ">
                                {/* <div className=" form-group ml-3 form-inline">
                                <button className="btn-black active-all" data-toggle="modal" data-target="#popup"><span><span className="fa fa-check"></span><span>{` `}</span>Pop up form</span></button>
                            </div> */}
                                <div className=" form-group ml-3 form-inline">
                                    <Link to={url + `/advertisement/diamonds`} >
                                        <button type="button" className="btn-black active-all">
                                            <span>
                                                <span className="fas fa-ad"></span>
                                                <span>{` `}</span>
                                                <FormattedMessage id="Advertisement" defaultMessage="Advertisement Histroy" />
                                            </span>
                                        </button>
                                    </Link>
                                </div>
                                <div className=" form-group ml-3 form-inline">
                                    <button type="button" className="btn-black active-all" onClick={handleActiveAll}><span><span className="fa fa-check"></span><span>{` `}</span> <FormattedMessage id="UpdateMyListings" defaultMessage="Update My Listingsss" /></span></button>
                                </div>
                                <div className=" form-group ml-3 form-inline">
                                    <button type="button" className="btn-black active-all" onClick={handleInActiveAll}><span><span className="fa fa-close"></span><span>{` `}</span> <FormattedMessage id="UnlistAllItems" defaultMessage="Unlist All Items" /></span></button>
                                </div>
                                <div className=" form-group ml-3 form-inline">
                                    <button type="button" className="btn-black active-all" onClick={handleDeleteAllClick}><span><span className="fa fa-trash"></span><span>{` `}</span> <FormattedMessage id="DeleteAllItems" defaultMessage="Delete All Items" /></span></button>
                                </div>
                                <div className="form-group mb-0 form-inline">
                                    <Link to={url + '/add-product'}>
                                        <button type="button" className="btn-black">
                                            <span className="icon-add_1"></span>
                                            <span>{` `}</span>
                                            <FormattedMessage
                                                id="addsingleproduct.add-new-product"
                                                defaultMessage="Add New Items"
                                            />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'side-filter-overlay' + (showFilters ? " show" : "")} onClick={() => setShowFilters(false)}></div>
                    <div className={'side-filter' + (showFilters ? " open" : "")}>
                        <div className='side-filter-inner '>
                            <button
                                className='close-filter'
                                id="close-side-filter"
                                onClick={() => setShowFilters(false)}
                            >&times;</button>
                            <div className='row'>
                                <MyProductsSideFilter setShowFilters={setShowFilters} />
                            </div>
                        </div>
                    </div>
                    <div className="titles mt-4  border-0">
                        <div className="filter_search_right">
                            <div className="search_cat_top border-0">
                                <div className="row">

                                    <div className="col-lg-12 col-md-12 col-sm-12 d-flex flex-wrap justify-content-md-end">
                                        <div className="form-group mb-0 mr-3 form-inline ">
                                            <input type="checkbox" id="sold" checked={sold} onChange={handleSoldProducts} />
                                            <label htmlFor="sold" className="mt-2"><FormattedMessage id="Show sold products" /></label>
                                        </div>
                                        <div className="form-group pb-0 sort_by_box">
                                            <p className="sort_b_h text-right" onClick={handleSortBy}>
                                                <b className="mr-2"><FormattedMessage id="Sort_By" />:</b>
                                                {filterdisplayname} <span className="icon-chevron_down">
                                                </span>
                                            </p>
                                            <div className="sort_by_list">
                                                <ul>
                                                    {
                                                        diamondsJson && diamondsJson.map((data, key) => {
                                                            return (
                                                                <li className="activess" onClick={() => handleAcendingFilter(data.value, data.type, data.display_name)}>{data.display_name}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row filter_search_right align-items-center justify-content-between" >
                        <div className="col-lg-6 col-md-6">
                            <div className="d-flex">
                                {/* <button
                                    type="button"
                                    className="btn-black active-all mb-3 mt-2"
                                    onClick={() => setShowFilters(true)}>
                                    <span>
                                        <span className="fas fa-filter"></span>
                                        <span>{' '}</span>
                                        <FormattedMessage
                                            id="addsingleproduct.all-filters"
                                            defaultMessage="All Filters"
                                        />
                                    </span>
                                </button> */}
                                <div className="filter_search_right">
                                    <div className="search_cat_top border-0">
                                        <div className="form-group pb-0 sort_by_box">
                                            <p
                                                className="sort_b_h text-right"
                                                onClick={handleSortByAction}
                                            >
                                                <b className="mr-2">
                                                    <FormattedMessage id="Select Actions" />:</b>
                                                {actionname}
                                                <span className="icon-chevron_down"></span>
                                            </p>
                                            <div className="sort_by_action">
                                                <ul>
                                                    {
                                                        selectActionJson && selectActionJson.map((data, key) => {
                                                            return (
                                                                <li className="activess" onClick={(e) => handleSelectAction(e, data.value)}>{data.display_name}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className=" col-lg-4 col-md-4 col-sm-4">
                            <div className="seach_filter">
                                <input type="text" value={search} onKeyPress={(e) => handleSearchFilter(e)} onChange={handleSearch} className="form-control" placeholder={useIntl().formatMessage({ id: 'SearchByStock' })} />
                                <span className="icon-search_1  input_icon position-absolute" onClick={handleSearchIconClick} style={{ cursor: 'pointer' }}></span>
                            </div>
                        </div>
                    </div>

                    <div className="tbles mb-5">
                        <table width="100%">
                            <tr>
                                <th>
                                    <div className="form-group mb-0 mini checks inss">
                                        <input className="" type="checkbox" id="checkall" checked={checkHead} onChange={() => selectAll(selectAllStock)} />
                                        <label htmlFor="checkall"></label>
                                    </div>
                                </th>
                                <th><FormattedMessage id="addsingleproduct.stockno" /></th>
                                <th><FormattedMessage id="Shape" defaultMessage="Shape" /></th>
                                <th><FormattedMessage id="addsingleproduct.carat" defaultMessage="Carat" /></th>
                                <th><FormattedMessage id="addsingleproduct.color" defaultMessage="Color" /></th>
                                <th><FormattedMessage id="addsingleproduct.clarity" defaultMessage="Clarity" /></th>
                                <th><FormattedMessage id="addsingleproduct.cut" defaultMessage="Cut" /></th>
                                <th><FormattedMessage id="addsingleproduct.pol" defaultMessage="Pol" /></th>
                                <th><FormattedMessage id="addsingleproduct.sym" defaultMessage="Sym" /></th>
                                <th><FormattedMessage id="addsingleproduct.Fluor" defaultMessage="Fluor" /></th>
                                <th><FormattedMessage id="Milky" defaultMessage="Milky" /></th>
                                <th><FormattedMessage id="Bgm" defaultMessage="No BGM" /></th>
                                <th><FormattedMessage id="Cert" defaultMessage="Cert" /></th>
                                {/* <th>Price/ct</th> */}
                                <th><FormattedMessage id="Disc" defaultMessage="Disc" /></th>
                                <th><FormattedMessage id="addsingleproduct.price" /></th>
                                <th><FormattedMessage id="Status" defaultMessage="Status" /></th>
                                <th><FormattedMessage id="Actions" /></th>
                            </tr>

                            {
                                myproduct && myproduct?.products && myproduct?.products?.data?.length > 0 ?
                                    myproduct && myproduct?.products && myproduct?.products?.data && myproduct?.products?.data?.map((data, key) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <div className="form-group mb-0 mini inss">
                                                        <input type="checkbox" id={data.id} name="check" checked={selectAllStock[key]} onChange={() => handleStockManagement(data.id, key)} />
                                                        <label htmlFor={data.id}></label>
                                                    </div>
                                                </td>
                                                <td onClick={(e) => handleDetails(data.id)}>{data && data.stock_number}</td>
                                                <td onClick={(e) => handleDetails(data.id)}>{pColmDisplay(data, 'a_shape')}</td>
                                                <td onClick={(e) => handleDetails(data.id)}>{data && data.carat && totalCarat(data)}</td>
                                                <td onClick={(e) => handleDetails(data.id)}>{pColmDisplay(data, 'a_color')}</td>
                                                <td onClick={(e) => handleDetails(data.id)}>{pColmDisplay(data, 'a_clarity')}</td>
                                                <td onClick={(e) => handleDetails(data.id)}>{data && data.a_cut && capitalizeFirstLetterWithOnlyLetter(pColmDisplay(data, 'a_cut'))}</td>
                                                <td onClick={(e) => handleDetails(data.id)}>{data && data.a_polish && capitalizeFirstLetterWithOnlyLetter(pColmDisplay(data, 'a_polish'))}</td>
                                                <td onClick={(e) => handleDetails(data.id)}>{data && data.a_symmetry && capitalizeFirstLetterWithOnlyLetter(pColmDisplay(data, 'a_symmetry'))}</td>
                                                <td onClick={(e) => handleDetails(data.id)}>{data && data.a_fluorescence && capitalizeFirstLetterWithOnlyLetter(pColmDisplay(data, 'a_fluorescence'))}</td>

                                                <td onClick={(e) => handleDetails(data.id)}>{pColmDisplay(data, 'milky')}</td>
                                                <td onClick={(e) => handleDetails(data.id)}>{data && data['bgm'] == "0" ? 'Yes' : "No"} </td>

                                                {/* {data && data.certificate_url ?
                                                    <td><a href={GenerateCertificatePdf(data.certificate_url)} target={GenerateCertificatePdf(data.certificate_url)}>{pColmDisplay(data, 'a_certificate')}</a></td>
                                                    :
                                                    <td>{pColmDisplay(data, 'a_certificate')}</td>
                                                } */}
                                                {
                                                    (() => {
                                                        if (data && data.certificate_url) {
                                                            return <td><a href={GenerateCertificatePdf(data.certificate_url)} target={GenerateCertificatePdf(data.certificate_url)}>{pColmDisplay(data, 'a_certificate')}</a></td>
                                                        }
                                                        else if (pColmDisplay(data, 'a_certificate') == "HRD") {
                                                            return <td><a href={hrdurl + data.certificate_number} target="_blank">{pColmDisplay(data, 'a_certificate')}</a></td>
                                                        } else if (data.certificate == "25" && pColmDisplay(data, 'a_certificate') == "GIA") {
                                                            return <td><a href={giaurl + data.certificate_number} target="_blank">{pColmDisplay(data, 'a_certificate')}</a></td>
                                                        } else {
                                                            return <td>{pColmDisplay(data, 'a_certificate')}</td>
                                                        }
                                                    })()
                                                }

                                                {/* <td>${data && data.price && numberWithCommas(parseFloat(data.price).toFixed(2))}</td> */}
                                                <td onClick={(e) => handleDetails(data.id)}>
                                                    {data && data?.discount && totalPriceDisplayforDiscount(data)} </td>
                                                <td onClick={(e) => handleDetails(data.id)}>{
                                                    totalPriceDisplay(data)}</td>

                                                <td className="text-right Active-st-td">
                                                    <span
                                                        style={{ color: data && data?.availability_status?.attribute_id === 2316 ? 'green' : 'red', fontWeight: '600' }}>
                                                        {pColmDisplay(data, 'availability_status')}
                                                    </span>
                                                    <div className="more">
                                                        <span><img src={IMAGES.THREE_DOT} alt="" /></span>
                                                        <ul>
                                                            {
                                                                data && data?.availability_status?.attribute_id === 2316 ?
                                                                    <li onClick={(e) => handleStatusChange(2320, data.id)}><h6><FormattedMessage id="On_Memo" /></h6></li>
                                                                    :
                                                                    <li onClick={(e) => handleStatusChange(2316, data.id)}><h6><FormattedMessage id="Available" /></h6></li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </td>
                                                <td className="text-right Active-st-td">
                                                    <span style={{ color: data.status === 1 ? 'green' : 'red', fontWeight: '600' }}>
                                                        {data.status === 1 ? <FormattedMessage id="Listed" /> : <FormattedMessage id="Unlisted" />}
                                                    </span>
                                                    <div className="more">
                                                        <span><img src={IMAGES.THREE_DOT} alt="" /></span>
                                                        <ul>
                                                            <li>
                                                                <h6 onClick={(e) => handlecheckPlan(data.id)}>
                                                                    <FormattedMessage id="Advertise This Item" />
                                                                </h6>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    to={{ pathname: url + '/edit-full-product/' + data.id }}
                                                                    style={{ paddingTop: '0px' }}>
                                                                    <h6><FormattedMessage id="Edit Product" /></h6>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    to={{
                                                                        pathname: url + '/edit-product/' + data?.id,
                                                                        // query: {
                                                                        //     'discount': data.discount,
                                                                        //     'price': data.price,
                                                                        //     category_slug
                                                                        // }
                                                                        search: `?discount=${data?.discount}&price=${data?.price}&category_slug=${category_slug}&is_fancy=${data?.is_fancy}`
                                                                    }}
                                                                    style={{ paddingTop: '0px' }}>
                                                                    <h6><FormattedMessage id="Edit Price" /></h6>
                                                                </Link>
                                                            </li>
                                                            {
                                                                data?.status === 1 ?
                                                                    <li onClick={(e) => handleSingleActivate('deactivate', data.id)}>
                                                                        <h6><FormattedMessage id="Unlist Item" /></h6>
                                                                    </li>
                                                                    :
                                                                    <li onClick={(e) => handleSingleActivate('active', data.id)}>
                                                                        <h6><FormattedMessage id="List Item" /></h6>
                                                                    </li>
                                                                // <img src={IMAGES.EDIT_PNG} className="mr-2" alt="" />
                                                            }
                                                            <li onClick={(e) => handleSingleActivate('delete', data.id)}>
                                                                <h6><FormattedMessage id="Delete" /></h6>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <th colspan="12"><FormattedMessage id="ProductNotFound" defaultMessage="Product not Found" /></th>
                                    </tr>
                            }

                        </table>
                    </div>
                    <div className="table_pagination">
                        <ReactPaginate
                            previousLabel={<i className="icon icon-chevron_gray-left"></i>}
                            nextLabel={<i className="icon icon-chevron_black"></i>}
                            pageCount={pageCount}
                            onPageChange={handlePageChange}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                            forcePage={props?.pageReset}
                        />
                    </div>
                </div>
                <Popup />
            </Fragment>
            {
                <PopupConfirm
                    message={useIntl().formatMessage({ id: 'deleteAllProducts' })}
                    title={useIntl().formatMessage({ id: 'Confirmation' })}
                    handleResForm={handleResForm}
                />
            }
        </Fragment>
    )
}

export default WhiteDimonds