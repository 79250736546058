import React, { Fragment, useState, useEffect } from 'react';
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import MetaData from '../../layout/MetaData';
import { IMAGES } from '../../../constants';
import { JEWELER_SMALL, JEWELER_SMALL_SEARCHES } from '../../../constants/index';
import { submitPaymentInfo } from '../../../actions/userActions';
import { flip, savePercentage, movetoNext, setColor, deviceDisplay, numberWithCommas } from '../../../helper';
import { Link, useHistory, useLocation } from "react-router-dom";
import { validateFields } from '../../../validation';
import classnames from 'classnames';
import { getPlanByRole } from '../../../actions/userActions';
import { FormattedMessage } from 'react-intl';
import { currency, url } from '../../../config';
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';
import Loader from '../../layout/Loader';

import {
    // CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    // Elements,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { toast, ToastContainer } from 'react-toastify';
import { PurchasePlanStripeAction } from '../../../actions/stripeAction';
import Confirmation from './Confirmation';

const BillingInformation = ({ rerenderParentCallback }) => {
    const history = useHistory();

    const dispatch = useDispatch()
    const alert = useAlert()
    const location = useLocation()
    const [loaderStripe, setLoaderStripe] = useState(false)
    const { error, paymentInfoData, paymentscript } = useSelector(state => state.paymentData)
    const { user } = useSelector(state => state.user);
    const { plan_role } = useSelector(state => state.planByRole);
    const { payment_loading, payment_status, payment_data, payment_error } = useSelector((state) => state.PurchasePlanStripeState)

    const stripe = useStripe();
    const elements = useElements();
    const [selectedPlan, setSelectedPlan] = useState({
        planName: "",
        planPrice: "",
        stripePlanId: ""
    });


    useEffect(() => {
        $('#busi-info-sidebar').addClass('visited')
        $('#bill-info-sidebar').addClass('active')
    })

    useEffect(() => {
        dispatch(getPlanByRole());
    }, [dispatch])

    useEffect(() => {
        if (paymentInfoData) {
            alert.success(paymentInfoData)
            if (paymentscript) {
                var script = document.createElement("script");
                script.innerHTML = paymentscript;
                document.body.appendChild(script);
            } else {
                rerenderParentCallback()
            }
        }
        if (error) {
            alert.error(error)
        }
    }, [error, paymentInfoData, rerenderParentCallback, alert, paymentscript])

    const [card_number, setCardNumber] = useState({
        CardNumberFirst: '',
        CardNumberSecond: '',
        CardNumberThree: '',
        CardNumberFour: ''
    })

    const [expires, setCardExpire] = useState({
        month: '',
        year: ''
    })

    const [paymentInfo, setPaymentInfo] = useState({
        plan_id: "",
        plan_payment_period: 1,
        plan_name: "",
        // card_holder_name: user.name,
        // cvv: "",
        check: false
    })

    const [paymentError, setPaymentError] = useState({
        subscriptionError: '',
        planPeriodError: '',
        // cvvError: '',
        checkError: '',
        // monthError: '',
        // yearError: '',
        // cardNumberFirstError: '',
        // cardNumberSecondError: '',
        // cardNumberThreeError: '',
        // cardNumberFourError: ''
    })

    const handlePlanPaymentPeriod = (event) => {
        // setPaymentInfo({ ...paymentInfo, plan_id: "" })
        $(".all-plan").removeClass("sub_active")
        // setPaymentInfo(plan_id)
        if (event.target.checked == true) {
            setPaymentInfo({ ...paymentInfo, plan_payment_period: 2, plan_id: "" });
        } else {
            setPaymentInfo({ ...paymentInfo, plan_payment_period: 1, plan_id: "" });
        }
    }

    const handleCheck = (event) => {
        setPaymentInfo({ ...paymentInfo, check: event.target.checked });
        // setPaymentError({ ...paymentError, checkError, })
    }

    const handleSubscription = (plan_type, plan_name) => {
        $('.sub_plan_box').removeClass('sub_active');
        $('.pln-' + plan_type + ' .sub_plan_box').addClass('sub_active');
        setPaymentInfo({ ...paymentInfo, plan_id: plan_type, plan_name: plan_name });
    }

    const handleCardNumber = (event) => {
        setCardNumber({ ...card_number, [event.target.name]: event.target.value });
    }

    const handleExpire = (event) => {
        setCardExpire({ ...expires, [event.target.name]: event.target.value });
    }

    const handlePaymentInfo = (event) => {
        setPaymentInfo({ ...paymentInfo, [event.target.name]: event.target.value });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const planPeriodError = validateFields.validatePlan(paymentInfo.plan_payment_period);
        const subscriptionError = validateFields.validateSubscription(paymentInfo.plan_id);
        // const cvvError = validateFields.validateCvv(paymentInfo.cvv);
        const checkError = validateFields.validateTerm(paymentInfo.check);
        // const monthError = validateFields.validateMonth(expires.month);
        // const yearError = validateFields.validateYear(expires.year);
        // const cardNumberFirstError = validateFields.validateCardNumber(card_number.CardNumberFirst);
        // const cardNumberSecondError = validateFields.validateCardNumber(card_number.CardNumberSecond);
        // const cardNumberThreeError = validateFields.validateCardNumber(card_number.CardNumberThree);
        // const cardNumberFourError = validateFields.validateCardNumber(card_number.CardNumberFour);


        if ([subscriptionError,
            planPeriodError,
            // cvvError,
            checkError,
            // monthError,
            // yearError,
            // cardNumberFirstError,
            // cardNumberSecondError,
            // cardNumberThreeError,
            // cardNumberFourError
        ].every(e => e === false)) {
            setPaymentError({
                ...paymentError,
                subscriptionError,
                planPeriodError,
                // cvvError,
                checkError,
                // monthError,
                // yearError,
                // cardNumberFirstError,
                // cardNumberSecondError,
                // cardNumberThreeError,
                // cardNumberFourError
            })

            // history.push(url + "/purchase-subscription-plan", { planid: paymentInfo.plan_id, userid: user?.id, planName: paymentInfo.plan_name });
            // navigate(url + "/purchase-subscription-plan")
            //            dispatch(submitPaymentInfo(paymentInfo));

        }
        else {
            if (subscriptionError) {
                alert.error(subscriptionError);
            }

            setPaymentError({
                ...paymentError,
                subscriptionError,
                planPeriodError,
                // cvvError,
                checkError,
                // monthError,
                // yearError,
                // cardNumberFirstError,
                // cardNumberSecondError,
                // cardNumberThreeError,
                // cardNumberFourError
            })

            // if (!cardNumberFirstError && !cardNumberSecondError && !cardNumberThreeError && !cardNumberFourError && !monthError && !yearError) {
            //     if (!$('.debit-card').hasClass('flipped')) {
            //         $('.debit-card').addClass('flipped');
            //     }
            // }
            // else {
            //     return;
            // }
        }

    }

    const selectPlan = (stripePlanId, planName, planPrice, planId) => {
        //reg-plans
        $(".all-plan").removeClass("sub_active")
        $(".pln-box-" + planId).addClass("sub_active")
        setSelectedPlan({
            planName: planName,
            planPrice: planPrice,
            stripePlanId: stripePlanId
        })
        setPaymentInfo({ ...paymentInfo, plan_id: planId, plan_name: planName });

    }





    const handleStripe = async (event) => {
        event.preventDefault();
        const checkError = validateFields.validateTerm(paymentInfo.check);
        const subscriptionError = validateFields.validateSubscription(paymentInfo.plan_id);

        if (paymentInfo.check === false) {
            setPaymentError({ ...paymentError, checkError, })
        } else {
            setPaymentError({ ...paymentError, checkError: false, })
        }
        if (paymentInfo?.plan_id == "") {
            alert.error(subscriptionError)
        }
        if (paymentInfo?.plan_id !== "" && paymentInfo.check !== false) {

            // STRIPE CHECKOUT 

            setLoaderStripe(true)
            const payload = {
                price_id: selectedPlan.stripePlanId,
                success_url: url + "/payment-success",
                cancel_url: location?.pathname
            };
            await dispatch(PurchasePlanStripeAction(payload))
            setLoaderStripe(false)


            // STRIPE PAYMENT 

            // history.push(url + "/purchase-subscription-plan", { stripePlanId: selectedPlan?.stripePlanId, planName: selectedPlan?.planName, planPrice: selectedPlan?.planPrice });

            // IZIPAY PAYMENT 

            // history.push(url + "/purchase-subscription-plan", { planid: paymentInfo.plan_id, userid: user?.id, planName: paymentInfo.plan_name });

        }

    }
    useEffect(async () => {
        if (payment_data?.status) {
            window.location.href = payment_data?.data?.url

        } else if (payment_error) {
            await alert.error(payment_error);
        }
    }, [payment_data, payment_error])


    const stringReplace = (text) => {
        let replace = text.replace(/(<([^>]+)>)/ig, '');
        return replace;
    }

    return (
        <Fragment>
            {loaderStripe ? <Loader /> : ''}
            <div className="payment-page">
                <MetaData title={`billing information`} />
                <div className="card2 ml-2 billing-card show">
                    <div className="card_head">
                        <div className="card_head_left">
                            <h3 className="black-3B font-20 fm">
                                <FormattedMessage
                                    id="business.welcome"
                                    defaultMessage="Welcome"
                                />
                            </h3>
                            <h2>{user && user.name}</h2>
                        </div>
                        <p>{user && user.role == 1 ? <FormattedMessage id="register.jeweler" /> : <FormattedMessage id="register.supplier" />}</p>
                    </div>
                    <h4 className="font-20 black-3B fb mb-4">
                        <FormattedMessage
                            id="billing.subscription-plan"
                            defaultMessage="Subscription plan"
                        />
                    </h4>
                    <form onSubmit={handleStripe}>
                        <div className="row mb-3">
                            <div className="col-lg-12">
                                <div className="cus_switch mb-3  d-flex align-item-center">
                                    <p className="font-14 fr black-3B pr-3">
                                        <FormattedMessage
                                            id="billing.monthly"
                                            defaultMessage="Monthly"
                                        />
                                    </p>
                                    <label className="switch">
                                        <input type="checkbox" name="plan_payment_period" onChange={handlePlanPaymentPeriod} value={paymentInfo.plan_payment_period} />
                                        <span className="slider round"></span>
                                    </label>
                                    <p className="font-14 fr pl-3" style={{ color: "#236653", fontWeight: 600 }}><b
                                        className="black-3B">
                                        <FormattedMessage
                                            id="billing.annual"
                                            defaultMessage="Annual"
                                        />
                                    </b>
                                        <FormattedMessage
                                            id="billing.save-percentage"
                                            defaultMessage="Save up to 16.19%"
                                        />
                                    </p>
                                </div>
                            </div>
                            {
                                plan_role && plan_role.plans && plan_role.plans.map((plan) => {

                                    return (
                                        <a class={"col-lg-3 col-md-4 reg-plans pln-" + plan.id} onClick={() => selectPlan((paymentInfo?.plan_payment_period === 2 ? plan?.plan_id_year : plan?.plan_id_month), (plan?.name), (paymentInfo?.plan_payment_period === 2 ? plan?.yearly_price : plan?.monthly_price), plan.id)}>
                                            <div className={"sub_plan_box all-plan pln-box-" + plan.id}>
                                                <input type='hidden' name='plan_name' value={plan.name} />
                                                <h3 className={setColor(plan.order_by)}>{plan.name}</h3>
                                                {plan.user_type == 2 &&
                                                    <div className="device_d mb-3"><span className="fa fa-long-arrow-up"></span><p>
                                                        {/* {plan.upload_limit == "Unlimited upload" ? */}
                                                        {plan.upload_limit && plan.upload_limit.toLowerCase() === "unlimited" ?
                                                            localStorage.getItem('lang') == 'tr' ? ' Sınırsız Yükleme' : 'Unlimited upload'
                                                            :

                                                            localStorage.getItem('lang') == 'tr' ? `${currency}` + (numberWithCommas(plan.upload_limit)) + ' Yükleme' : `${currency}` + (numberWithCommas(plan.upload_limit)) + ' upload'}

                                                    </p></div>
                                                }

                                                {plan.id == JEWELER_SMALL ?
                                                    <div className="device_d">
                                                        <p>{JEWELER_SMALL_SEARCHES} <FormattedMessage id="Searches" /></p>
                                                    </div>
                                                    :
                                                    <div className="device_d">
                                                        <span className="icon-device_1"></span>
                                                        <span className="icon-device_2"></span>
                                                        <p>{plan.device_limit + " " + deviceDisplay(plan.device_limit)} </p>
                                                    </div>
                                                }
                                                <p style={{ fontWeight: "bold", color: "red" }}>
                                                    {
                                                        plan.features !== null ?
                                                            stringReplace(plan?.features)
                                                            : ""
                                                    }
                                                </p>

                                                {
                                                    paymentInfo.plan_payment_period == 1 ?
                                                        <h4 className="monthly-price">{currency}{plan.monthly_price}/<FormattedMessage id="mo" /></h4> :
                                                        <h4 className="yearly-price">{currency}{plan.yearly_price}/<FormattedMessage id="yr" /></h4>
                                                }
                                                {plan.monthly_price > 0 &&
                                                    <p><span>Save {savePercentage(plan.monthly_price, plan.yearly_price)}%</span> <FormattedMessage id="billing.billed-yearly" /></p>
                                                }
                                            </div>
                                            {/* </div> */}
                                        </a>
                                    )
                                })
                            }
                        </div>

                        <div className="row  mb-3">
                            <div className="col-md-6">
                                {/* <div className={classnames("form-control", { 'is-valid': paymentError.checkError === false }, { 'is-invalid': paymentError.checkError })}> */}
                                <div className={classnames("form-control ", { 'is-valid': paymentError.checkError === false }, { "is-invalid": paymentError.checkError })}>
                                    <input type="checkbox" id="check1" onChange={handleCheck} name="check" />
                                    <label htmlFor="check1">
                                        <FormattedMessage
                                            id="billing.agree"
                                            defaultMessage="I agree to Cevaheer"
                                        />
                                        <u>
                                            <a href={url + "/terms&conditions"} target="_blank">
                                                <FormattedMessage
                                                    id="billing.term"
                                                    defaultMessage="Terms & Conditions"
                                                />
                                            </a>
                                        </u></label>
                                </div>
                            </div>
                        </div>
                        <p style={{ color: "red" }}>{paymentError.checkError}</p>

                        <div className="form-group login_btn d-flex align-items-center mt-4">
                            <button type="submit" className="btn-lg btn-black next-button">
                                <FormattedMessage
                                    id="business.next"
                                    defaultMessage="Next"
                                />
                            </button>

                            {/* <a href={url+"/category/diamonds"} className="font-16 black-3B fb d-block ml-3">
                                    <FormattedMessage
                                        id="main.header.contactsales"
                                        defaultMessage="Contact Sales"
                                    />
                                </a> */}

                            <Link to={url + "/category/diamonds"} className="font-16 black-3B fb d-block ml-3" >
                                <FormattedMessage
                                    id="main.header.contactsales"
                                    defaultMessage="Contact Sales"
                                />
                            </Link>


                        </div>
                    </form>
                </div>

                <ToastContainer
                    autoClose={2500}
                    hideProgressBar={true}
                    className="toast-chilling"
                    position="top-right"
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                    theme="light"
                />
            </div>

        </Fragment>
    )
}

export default BillingInformation



















// import React, { Fragment, useState, useEffect } from 'react';
// import $ from 'jquery';
// import { useSelector, useDispatch } from 'react-redux';
// import { useAlert } from 'react-alert';
// import MetaData from '../../layout/MetaData';
// import { IMAGES } from '../../../constants';
// import { JEWELER_SMALL, JEWELER_SMALL_SEARCHES } from '../../../constants/index';
// import { submitPaymentInfo } from '../../../actions/userActions';
// import { flip, savePercentage, movetoNext, setColor, deviceDisplay, numberWithCommas } from '../../../helper';
// import { Link, useHistory, useLocation } from "react-router-dom";
// import { validateFields } from '../../../validation';
// import classnames from 'classnames';
// import { getPlanByRole } from '../../../actions/userActions';
// import { FormattedMessage } from 'react-intl';
// import { currency, url } from '../../../config';
// import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';
// import Loader from '../../layout/Loader';

// import {
//     // CardElement,
//     CardNumberElement,
//     CardExpiryElement,
//     CardCvcElement,
//     // Elements,
//     useStripe,
//     useElements,
// } from "@stripe/react-stripe-js";
// import { toast, ToastContainer } from 'react-toastify';
// import { PurchasePlanStripeAction } from '../../../actions/stripeAction';
// import Confirmation from './Confirmation';

// const BillingInformation = ({ rerenderParentCallback }) => {
//     const history = useHistory();

//     const dispatch = useDispatch()
//     const alert = useAlert()
//     const location = useLocation()
//     const [loaderStripe, setLoaderStripe] = useState(false)
//     const { error, paymentInfoData, paymentscript } = useSelector(state => state.paymentData)
//     const { user } = useSelector(state => state.user);
//     const { plan_role } = useSelector(state => state.planByRole);
//     const { payment_loading, payment_status, payment_data, payment_error } = useSelector((state) => state.PurchasePlanStripeState)

//     const stripe = useStripe();
//     const elements = useElements();
//     const [selectedPlan, setSelectedPlan] = useState({
//         planName: "",
//         planPrice: "",
//         stripePlanId: ""
//     });


//     useEffect(() => {
//         $('#busi-info-sidebar').addClass('visited')
//         $('#bill-info-sidebar').addClass('active')
//     })

//     useEffect(() => {
//         dispatch(getPlanByRole());
//     }, [dispatch])

//     useEffect(() => {
//         if (paymentInfoData) {
//             alert.success(paymentInfoData)
//             if (paymentscript) {
//                 var script = document.createElement("script");
//                 script.innerHTML = paymentscript;
//                 document.body.appendChild(script);
//             } else {
//                 rerenderParentCallback()
//             }
//         }
//         if (error) {
//             alert.error(error)
//         }
//     }, [error, paymentInfoData, rerenderParentCallback, alert, paymentscript])

//     const [card_number, setCardNumber] = useState({
//         CardNumberFirst: '',
//         CardNumberSecond: '',
//         CardNumberThree: '',
//         CardNumberFour: ''
//     })

//     const [expires, setCardExpire] = useState({
//         month: '',
//         year: ''
//     })

//     const [paymentInfo, setPaymentInfo] = useState({
//         plan_id: "",
//         plan_payment_period: 1,
//         plan_name: "",
//         // card_holder_name: user.name,
//         // cvv: "",
//         check: false
//     })

//     const [paymentError, setPaymentError] = useState({
//         subscriptionError: '',
//         planPeriodError: '',
//         // cvvError: '',
//         checkError: '',
//         // monthError: '',
//         // yearError: '',
//         // cardNumberFirstError: '',
//         // cardNumberSecondError: '',
//         // cardNumberThreeError: '',
//         // cardNumberFourError: ''
//     })

//     const handlePlanPaymentPeriod = (event) => {
//         // setPaymentInfo({ ...paymentInfo, plan_id: "" })
//         $(".all-plan").removeClass("sub_active")
//         // setPaymentInfo(plan_id)
//         if (event.target.checked == true) {
//             setPaymentInfo({ ...paymentInfo, plan_payment_period: 2, plan_id: "" });
//         } else {
//             setPaymentInfo({ ...paymentInfo, plan_payment_period: 1, plan_id: "" });
//         }
//     }

//     const handleCheck = (event) => {
//         setPaymentInfo({ ...paymentInfo, check: event.target.checked });
//         // setPaymentError({ ...paymentError, checkError, })
//     }

//     const handleSubscription = (plan_type, plan_name) => {
//         $('.sub_plan_box').removeClass('sub_active');
//         $('.pln-' + plan_type + ' .sub_plan_box').addClass('sub_active');
//         setPaymentInfo({ ...paymentInfo, plan_id: plan_type, plan_name: plan_name });
//     }

//     const handleCardNumber = (event) => {
//         setCardNumber({ ...card_number, [event.target.name]: event.target.value });
//     }

//     const handleExpire = (event) => {
//         setCardExpire({ ...expires, [event.target.name]: event.target.value });
//     }

//     const handlePaymentInfo = (event) => {
//         setPaymentInfo({ ...paymentInfo, [event.target.name]: event.target.value });
//     }

//     const handleSubmit = (event) => {
//         event.preventDefault();
//         const planPeriodError = validateFields.validatePlan(paymentInfo.plan_payment_period);
//         const subscriptionError = validateFields.validateSubscription(paymentInfo.plan_id);
//         // const cvvError = validateFields.validateCvv(paymentInfo.cvv);
//         const checkError = validateFields.validateTerm(paymentInfo.check);
//         // const monthError = validateFields.validateMonth(expires.month);
//         // const yearError = validateFields.validateYear(expires.year);
//         // const cardNumberFirstError = validateFields.validateCardNumber(card_number.CardNumberFirst);
//         // const cardNumberSecondError = validateFields.validateCardNumber(card_number.CardNumberSecond);
//         // const cardNumberThreeError = validateFields.validateCardNumber(card_number.CardNumberThree);
//         // const cardNumberFourError = validateFields.validateCardNumber(card_number.CardNumberFour);


//         if ([subscriptionError,
//             planPeriodError,
//             // cvvError,
//             checkError,
//             // monthError,
//             // yearError,
//             // cardNumberFirstError,
//             // cardNumberSecondError,
//             // cardNumberThreeError,
//             // cardNumberFourError
//         ].every(e => e === false)) {
//             setPaymentError({
//                 ...paymentError,
//                 subscriptionError,
//                 planPeriodError,
//                 // cvvError,
//                 checkError,
//                 // monthError,
//                 // yearError,
//                 // cardNumberFirstError,
//                 // cardNumberSecondError,
//                 // cardNumberThreeError,
//                 // cardNumberFourError
//             })

//             // history.push(url + "/purchase-subscription-plan", { planid: paymentInfo.plan_id, userid: user?.id, planName: paymentInfo.plan_name });
//             // navigate(url + "/purchase-subscription-plan")
//             //            dispatch(submitPaymentInfo(paymentInfo));

//         }
//         else {
//             if (subscriptionError) {
//                 alert.error(subscriptionError);
//             }

//             setPaymentError({
//                 ...paymentError,
//                 subscriptionError,
//                 planPeriodError,
//                 // cvvError,
//                 checkError,
//                 // monthError,
//                 // yearError,
//                 // cardNumberFirstError,
//                 // cardNumberSecondError,
//                 // cardNumberThreeError,
//                 // cardNumberFourError
//             })

//             // if (!cardNumberFirstError && !cardNumberSecondError && !cardNumberThreeError && !cardNumberFourError && !monthError && !yearError) {
//             //     if (!$('.debit-card').hasClass('flipped')) {
//             //         $('.debit-card').addClass('flipped');
//             //     }
//             // }
//             // else {
//             //     return;
//             // }
//         }

//     }

//     const selectPlan = (stripePlanId, planName, planPrice, planId) => {
//         //reg-plans
//         $(".all-plan").removeClass("sub_active")
//         $(".pln-box-" + planId).addClass("sub_active")
//         setSelectedPlan({
//             planName: planName,
//             planPrice: planPrice,
//             stripePlanId: stripePlanId
//         })
//         setPaymentInfo({ ...paymentInfo, plan_id: planId, plan_name: planName });

//     }





//     const handleStripe = async (event) => {
//         event.preventDefault();
//         const checkError = validateFields.validateTerm(paymentInfo.check);
//         const subscriptionError = validateFields.validateSubscription(paymentInfo.plan_id);

//         if (paymentInfo.check === false) {
//             setPaymentError({ ...paymentError, checkError, })
//         } else {
//             setPaymentError({ ...paymentError, checkError: false, })
//         }
//         if (paymentInfo?.plan_id == "") {
//             alert.error(subscriptionError)
//         }
//         if (paymentInfo?.plan_id !== "" && paymentInfo.check !== false) {

//             // STRIPE CHECKOUT 

//             setLoaderStripe(true)
//             const payload = {
//                 price_id: selectedPlan.stripePlanId,
//                 success_url: url + "/payment-success",
//                 cancel_url: location?.pathname
//             };
//             await dispatch(PurchasePlanStripeAction(payload))
//             setLoaderStripe(false)


//             // STRIPE PAYMENT 

//             // history.push(url + "/purchase-subscription-plan", { stripePlanId: selectedPlan?.stripePlanId, planName: selectedPlan?.planName, planPrice: selectedPlan?.planPrice });

//             // IZIPAY PAYMENT 

//             // history.push(url + "/purchase-subscription-plan", { planid: paymentInfo.plan_id, userid: user?.id, planName: paymentInfo.plan_name });

//         }

//     }
//     useEffect(async () => {
//         if (payment_data?.status) {
//             window.location.href = payment_data?.data?.url

//         } else if (payment_error) {
//             await alert.error(payment_error);
//         }
//     }, [payment_data, payment_error])


//     const stringReplace = (text) => {
//         let replace = text.replace(/(<([^>]+)>)/ig, '');
//         return replace;
//     }
//     return (
//         <Fragment>
//             {loaderStripe ? <Loader /> : ''}
//             <div className="payment-page">
//                 <MetaData title={`billing information`} />
//                 <div className="card2 ml-2 billing-card show">
//                     <div className="card_head">
//                         <div className="card_head_left">
//                             <h3 className="black-3B font-20 fm">
//                                 <FormattedMessage
//                                     id="business.welcome"
//                                     defaultMessage="Welcome"
//                                 />
//                             </h3>
//                             <h2>{user && user.name}</h2>
//                         </div>
//                         <p>{user && user.role == 1 ? <FormattedMessage id="register.jeweler" /> : <FormattedMessage id="register.supplier" />}</p>
//                     </div>
//                     <h4 className="font-20 black-3B fb mb-4">
//                         <FormattedMessage
//                             id="billing.subscription-plan"
//                             defaultMessage="Subscription plan"
//                         />
//                     </h4>
//                     <form onSubmit={handleStripe}>
//                         <div className="row mb-3">
//                             <div className="col-lg-12">
//                                 <div className="cus_switch mb-3  d-flex align-item-center">
//                                     <p className="font-14 fr black-3B pr-3">
//                                         <FormattedMessage
//                                             id="billing.monthly"
//                                             defaultMessage="Monthly"
//                                         />
//                                     </p>
//                                     <label className="switch">
//                                         <input type="checkbox" name="plan_payment_period" onChange={handlePlanPaymentPeriod} value={paymentInfo.plan_payment_period} />
//                                         <span className="slider round"></span>
//                                     </label>
//                                     <p className="font-14 fr pl-3" style={{ color: "#236653", fontWeight: 600 }}><b
//                                         className="black-3B">
//                                         <FormattedMessage
//                                             id="billing.annual"
//                                             defaultMessage="Annual"
//                                         />
//                                     </b>
//                                         <FormattedMessage
//                                             id="billing.save-percentage"
//                                             defaultMessage="Save up to 16.19%"
//                                         />
//                                     </p>
//                                 </div>
//                             </div>
//                             {
//                                 plan_role && plan_role.plans && plan_role.plans.map((plan) => {

//                                     return (
//                                         <a class={"col-lg-3 col-md-4 reg-plans pln-" + plan.id} onClick={() => selectPlan((paymentInfo?.plan_payment_period === 2 ? plan?.plan_id_year : plan?.plan_id_month), (plan?.name), (paymentInfo?.plan_payment_period === 2 ? plan?.yearly_price : plan?.monthly_price), plan.id)}>
//                                             <div className={"sub_plan_box all-plan pln-box-" + plan.id}>
//                                                 <input type='hidden' name='plan_name' value={plan.name} />
//                                                 <h3 className={setColor(plan.order_by)}>{plan.name}</h3>
//                                                 <p>{stringReplace(plan?.features)}</p>

//                                                 {plan.user_type == 2 &&
//                                                     <div className="device_d mb-3"><span className="fa fa-long-arrow-up"></span><p>
//                                                         {plan.upload_limit == "Unlimited upload" ?
//                                                             localStorage.getItem('lang') == 'tr' ? ' Sınırsız Yükleme' : 'Unlimited upload'
//                                                             :

//                                                             localStorage.getItem('lang') == 'tr' ? `${currency}` + (numberWithCommas(plan.upload_limit)) + ' Yükleme' : `${currency}` + (numberWithCommas(plan.upload_limit)) + ' upload'}

//                                                     </p></div>
//                                                 }

//                                                 {plan.id == JEWELER_SMALL ?
//                                                     <div className="device_d">
//                                                         <p>{JEWELER_SMALL_SEARCHES} <FormattedMessage id="Searches" /></p>
//                                                     </div>
//                                                     :
//                                                     <div className="device_d">
//                                                         <span className="icon-device_1"></span>
//                                                         <span className="icon-device_2"></span>
//                                                         <p>{plan.device_limit + " " + deviceDisplay(plan.device_limit)} </p>
//                                                     </div>
//                                                 }

//                                                 {
//                                                     paymentInfo.plan_payment_period == 1 ?
//                                                         <h4 className="monthly-price">{currency}{plan.monthly_price}/<FormattedMessage id="mo" /></h4> :
//                                                         <h4 className="yearly-price">{currency}{plan.yearly_price}/<FormattedMessage id="yr" /></h4>
//                                                 }
//                                                 {plan.monthly_price > 0 &&
//                                                     <p><span>Save {savePercentage(plan.monthly_price, plan.yearly_price)}%</span> <FormattedMessage id="billing.billed-yearly" /></p>
//                                                 }
//                                             </div>
//                                             {/* </div> */}
//                                         </a>
//                                     )
//                                 })
//                             }
//                         </div>

//                         <div className="row  mb-3">
//                             <div className="col-md-6">
//                                 {/* <div className={classnames("form-control", { 'is-valid': paymentError.checkError === false }, { 'is-invalid': paymentError.checkError })}> */}
//                                 <div className={classnames("form-control ", { 'is-valid': paymentError.checkError === false }, { "is-invalid": paymentError.checkError })}>
//                                     <input type="checkbox" id="check1" onChange={handleCheck} name="check" />
//                                     <label htmlFor="check1">
//                                         <FormattedMessage
//                                             id="billing.agree"
//                                             defaultMessage="I agree to Cevaheer"
//                                         />
//                                         <u>
//                                             <FormattedMessage
//                                                 id="billing.term"
//                                                 defaultMessage="Terms & Conditions"
//                                             />
//                                         </u></label>
//                                 </div>
//                             </div>
//                         </div>
//                         <p style={{ color: "red" }}>{paymentError.checkError}</p>

//                         <div className="form-group login_btn d-flex align-items-center mt-4">
//                             <button type="submit" className="btn-lg btn-black next-button">
//                                 <FormattedMessage
//                                     id="business.next"
//                                     defaultMessage="Next"
//                                 />
//                             </button>

//                             {/* <a href={url+"/category/diamonds"} className="font-16 black-3B fb d-block ml-3">
//                                     <FormattedMessage
//                                         id="main.header.contactsales"
//                                         defaultMessage="Contact Sales"
//                                     />
//                                 </a> */}

//                             <Link to={url + "/category/diamonds"} className="font-16 black-3B fb d-block ml-3" >
//                                 <FormattedMessage
//                                     id="main.header.contactsales"
//                                     defaultMessage="Contact Sales"
//                                 />
//                             </Link>


//                         </div>
//                     </form>
//                 </div>

//                 <ToastContainer
//                     autoClose={2500}
//                     hideProgressBar={true}
//                     className="toast-chilling"
//                     position="top-right"
//                     newestOnTop={false}
//                     closeOnClick={true}
//                     rtl={false}
//                     pauseOnFocusLoss={false}
//                     draggable={false}
//                     pauseOnHover={false}
//                     theme="light"
//                 />
//             </div>

//         </Fragment>
//     )
// }

// export default BillingInformation